import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { useMsal } from "@azure/msal-react";

export const EditCell = ({ row, column, table }) => {
    const meta = table.options.meta

    const validRow = meta?.validRows[row.id];
    const disableSubmit = validRow ? Object.values(validRow)?.some(item => !item) : false;
  
    //console.log("DISABLED:", disableSubmit, row.id, meta?.validRows[row.id]);

    const { instance, accounts } = useMsal();

    const setEditedRows = e => {
      const elName = e.currentTarget.name

      meta?.setEditedRows(old => ({
        ...old,
        [row.id]: !old[row.id]
      }))
  
      if (elName === "done") {      
        const accessTokenRequest = {
              scopes: [process.env.REACT_APP_API_SCOPE],
              account: accounts[0],
            };

            const dtable = {datatable: column.columnDef.db_table, data: row.original}
            //        body: JSON.stringify(row.original)

            instance
            .acquireTokenSilent(accessTokenRequest)
              .then((accessTokenResponse) => {
                // Acquire token silent success
                let accessToken = accessTokenResponse.accessToken;
                // Call API with token       

                const requestOptions = {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}`, 'x-api-key': process.env.REACT_APP_API_KEY },
                  body: JSON.stringify(dtable)
                };
        
                fetch(process.env.REACT_APP_API_URL, requestOptions)
                  .then(response => response.json())
                  .then(data => {
                    meta?.updateData(row.index, "record_id", data["record_id"], true)
                    meta?.updateData(row.index, "created_on", data["created_on"], true)
                    console.log(row.index, column.id, data)
                    //meta?.updateData(row.index, column.memberid, data)
                  });              
            })
      }
      
      if (elName !== "edit") {
        if (elName === "cancel") {
          console.log("CANCEL:",row.index, row.original);
          if (!row.original?.memberid) 
            meta?.removeRow(row.index, false);
        }
        meta?.revertData(row.index, e.currentTarget.name === "cancel")
        //e.currentTarget.name === "cancel" ? meta?.revertData(row.index) : meta?.updateRow(row.index);
        console.log("HERE-EDIT", elName);
      }
    }
  
    const removeRow = () => {
      meta?.removeRow(row.index);
    }
  
    return (
      <div className="edit-cell-container">
        {meta?.editedRows[row.id] ? (
          <div className="edit-cell">
            <button onClick={setEditedRows} name="cancel">
            <FontAwesomeIcon icon={faX} />
            </button>
            <button onClick={setEditedRows} name="done" disabled={disableSubmit} >
            <FontAwesomeIcon icon={faCheck} />
            </button>
          </div>
        ) : (
          <div className="edit-cell-action">
          <button onClick={setEditedRows} name="edit">
          <FontAwesomeIcon icon={faPencil} />
          </button>
          <button onClick={removeRow} name="remove">
              <FontAwesomeIcon icon={faTrashCan} />
          </button>
          </div>   
        )}
      </div>
    )
  }