/*
export const vaccines = [
    { value: "107", label: 'diphtheria, tetanus toxoids and acellular pertussis vaccine, unspecified formulation' },
    { value: "17", label: 'Haemophilus influenzae type b vaccine, conjugate unspecified formulation' },
    { value: "50", label: 'DTaP-Haemophilus influenzae type b conjugate vaccine' },
    { value: "85", label: 'hepatitis A vaccine, unspecified formulation' },
    { value: "45", label: 'hepatitis B vaccine, unspecified formulation' },
    { value: "137", label: 'HPV, unspecified formulation' },
    { value: "89", label: 'poliovirus vaccine, unspecified formulation' },
    { value: "88", label: 'influenza virus vaccine, unspecified formulation' },
    { value: "111", label: 'influenza virus vaccine, live, attenuated, for intranasal use' },
    { value: "03", label: 'measles, mumps and rubella virus vaccine' },
    { value: "108", label: 'meningococcal ACWY vaccine, unspecified formulation' },
    { value: "152", label: 'Pneumococcal Conjugate, unspecified formulation' },
    { value: "122", label: 'rotavirus vaccine, unspecified formulation' },
    { value: "06", label: 'rubella virus vaccine' },
    { value: "115", label: 'tetanus toxoid, reduced diphtheria toxoid, and acellular pertussis vaccine, adsorbed' },
    { value: "138", label: 'tetanus and diphtheria toxoids, not adsorbed, for adult use' },
    { value: "21", label: 'varicella virus vaccine' },
]
*/

export const vaccines = [
    { value: '', label: 'Please Select ...' },
    { label: 'diphtheria, tetanus toxoids and acellular pertussis vaccine, unspecified formulation' },
    { label: 'DTaP-Haemophilus influenzae type b conjugate vaccine' },
    { label: 'Haemophilus influenzae type b vaccine, conjugate unspecified formulation' },
    { label: 'hepatitis A vaccine, unspecified formulation' },
    { label: 'hepatitis B vaccine, unspecified formulation' },
    { label: 'HPV, unspecified formulation' },
    { label: 'influenza virus vaccine, unspecified formulation' },
    { label: 'influenza virus vaccine, live, attenuated, for intranasal use' },
    { label: 'measles, mumps and rubella virus vaccine' },
    { label: 'meningococcal ACWY vaccine, unspecified formulation' },
    { label: 'Pneumococcal Conjugate, unspecified formulation' },
    { label: 'poliovirus vaccine, unspecified formulation' },
    { label: 'rotavirus vaccine, unspecified formulation' },
    { label: 'rubella virus vaccine' },
    { label: 'tetanus toxoid, reduced diphtheria toxoid, and acellular pertussis vaccine, adsorbed' },
    { label: 'tetanus and diphtheria toxoids, not adsorbed, for adult use' },
    { label: 'varicella virus vaccine' },
]
    
    
export const vaccines_cvx = [
    "107",
    "50" ,
    "17" ,
    "85" ,
    "45" ,
    "137" ,
    "111" ,
    "88" ,
    "03" ,
    "108" ,
    "152" ,
    "89" ,
    "122" ,
    "06" ,
    "138"  ,
    "115" ,
    "21" ,
]
    
/*
export const vaccines_cvx = [
    { value: "107" },
    { value: "17" },
    { value: "50" },
    { value: "85" },
    { value: "45" },
    { value: "137" },
    { value: "89" },
    { value: "88" },
    { value: "111" },
    { value: "03" },
    { value: "108" },
    { value: "152" },
    { value: "122" },
    { value: "06" },
    { value: "115" },
    { value: "138"  },
    { value: "21" },
]
*/
/*
107
17
50
85
45
137
89
88
111
03
108
152
122
06
115
138
21
*/













