import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faClipboardUser } from "@fortawesome/free-solid-svg-icons";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons";

export default function TemporaryDrawer({ state, setState, toggleDrawer }) {

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 275 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {['CIS-IMA', 'CBP', 'PPC', 'GSD', 'EED', 'BPD', 'CCS', 'LSC', 'WCV', 'WCC'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton to={text}>
              <ListItemIcon style={{minWidth: '40px'}} >
                {index % 2 === 0 ? <FontAwesomeIcon icon={faClipboardList} size="lg" /> : <FontAwesomeIcon icon={faClipboardList} size="lg" />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      {/*
      <List>
        {['DHCFImmunization', 'DHCFMMSBump', 'ImmunizationSupplemental'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton to={text}>
              <ListItemIcon style={{minWidth: '40px'}} >
                {index % 2 === 0 ? <FontAwesomeIcon icon={faClipboardList} size="lg" /> : <FontAwesomeIcon icon={faClipboardList} size="lg" />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      */}

      <List style={{ marginTop: `auto`, position: "fixed", bottom: 0,  textAlign: "center",    paddingBottom: 10, }} >
        <ListItem>
          <ListItemText>{process.env.REACT_APP_ENVIRONMENT}</ListItemText>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}