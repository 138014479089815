import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

import DialogActions from '@mui/material/DialogActions';
import moment from "moment";
import { useState } from "react"

export default function FilterDialog(props) {
  const { onClose, open } = props;
  //const { onClose, selectedValue, open } = props;
  const [value, setValue] = useState("")

  const handleClose = () => {
    onClose(value);
  };

  const onChangeDate = e => {
    //console.log("->", e.target.value)
   // const newDate = moment(new Date(e.target.value)).format('YYYY-MM-DD');
    const newDate = moment(e.target.value).format('YYYY-MM-DD');
    setValue(newDate);
   // console.log(newDate); //value picked from date picker
  };

  const handleCloseNone = () => {
    //onClose(selectedValue);
  };

  return (
    <Dialog onClose={handleCloseNone} open={open}>
    <DialogTitle>Filter Data</DialogTitle>

      <>
      <input type="date" onChange={onChangeDate}></input>
      </>

    <DialogActions>
      <Button autoFocus onClick={handleClose}>
        Continue
      </Button>
    </DialogActions>
  </Dialog>
  );
}

FilterDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};
