export const formselects = {
    'wcc': {  
    },
    'wcv': {
        'cpt' : [ 
            { value: '', label: "Please Select ..."},
            { label: '99381' },
            { label: '99382' },
            { label: '99383' },       
            { label: '99384' }, 
            { label: '99385' }, 
            { label: '99391' }, 
            { label: '99392' },    
            { label: '99393' },
            { label: '99394' },
            { label: '99395' },                                                                                                  
            { label: '99461' }
        ],
        'icd10': [ 
            { value: '', label: "Please Select ..."},
            { label: 'Z00.00' },
            { label: 'Z00.01' },
            { label: 'Z00.110' },
            { label: 'Z00.111' },
            { label: 'Z00.121' },
            { label: 'Z00.129' },
            { label: 'Z02.5' }
        ],
        'providerlocation': [ 
            { value: '', label: "Please Select ..."},
            { label: 'Children National' },
            { label: 'CNPA' },
            { label: 'Community of Hope' },
            { label: 'Core Health' },
            { label: 'EE Health Center' },
            { label: 'George Washington' },
            { label: 'Georgetown' },            
            { label: 'Howard' },
            { label: 'Howard University' },
            { label: 'Kaiser' },
            { label: 'Mary Center' }, 
            { label: 'Medstar-WHC' },                                                                     
            { label: 'Unity' }
        ],
        'nutritionactivity': [ 
            { value: '', label: "Please Select ..."},
            { label: 'Yes' },
            { label: 'No' }
        ],
        'physicalactivity': [ 
            { value: '', label: "Please Select ..."},
            { label: 'Yes' },
            { label: 'No' }
        ],
        'nutritionicd10': [ 
            { value: '', label: "Please Select ..."},
            { label: 'Z71.3' }
        ],
        'nutritioncpt': [ 
            { value: '', label: "Please Select ..."},
            { label: '97802' },
            { label: '97803' },
            { label: '97804' }
        ],
        'physicalactivityicd10': [ 
            { value: '', label: "Please Select ..."},
            { label: 'Z02.5' },
            { label: 'Z71.82' }
        ],
        'bmiicd10': [ 
            { value: '', label: "Please Select ..."},
            { label: 'Z68.51' },
            { label: 'Z68.52' },
            { label: 'Z68.53' },
            { label: 'Z68.54' }
        ]
    },
    'cbp': {
        'cpt' : [ 
            { value: '', label: "Please Select ..."},
            { label: '99213' },
        ],
        'icd10' : [ 
            { value: '', label: "Please Select ..."},
            { label: 'I10' },
        ],
        'cptiisystolic': [
            { value: '', label: "Please Select ..."},
            { label: '3074F', description: 'BP Less than 130 mm Hg' },                                                
            { label: '3075F', description: 'BP 130 - 139 mm Hg' },
            { label: '3077F', description: 'BP Greater than or equal to 140 mm Hg' }
        ], 
        'cptiidiastolic': [
            { value: '', label: "Please Select ..."},
            { label: '3078F' },                                                
            { label: '3079F' },
            { label: '3080F' }
        ],       
    },
    'ppc': {
        'cpt' : [ 
            { value: '', label: "Please Select ..."},
            { label: '99213' },
            { label: '99214' },
            { label: '59430' }
        ],
        'cptii' : [ 
            { value: '', label: "Please Select ..."},
            { label: '0500F' },
            { label: '0501F' },
            { label: '0503F' }
        ],
        'icd10': [ 
            { value: '', label: "Please Select ..."},
            { label: 'Z01.411' },
            { label: 'Z01.419' },
            { label: 'Z01.42' },
            { label: 'Z01.430' },
            { label: 'Z34.80' },
            { label: 'Z34.81' },
            { label: 'Z34.82' },
            { label: 'Z34.83' },
            { label: 'Z39.1' },
            { label: 'Z39.2' },
            { label: 'Z3A.01' },
            { label: 'Z3A.08' },
            { label: 'Z3A.09' },
            { label: 'Z3A.11' },
            { label: 'Z3A.11' },
            { label: 'Z3A.12' },
            { label: 'Z3A.13' },
            { label: 'Z3A.14' },
            { label: 'Z3A.15' },
            { label: 'Z3A.16' },
            { label: 'Z3A.17' },
            { label: 'Z3A.18' },
            { label: 'Z3A.19' },
            { label: 'Z3A.20' },
            { label: 'Z3A.21' },
            { label: 'Z3A.22' },
            { label: 'Z3A.23' },
            { label: 'Z3A.24' },
            { label: 'Z3A.25' },
            { label: 'Z3A.26' },
            { label: 'Z3A.27' },
            { label: 'Z3A.28' },
            { label: 'Z3A.29' },
            { label: 'Z3A.30' },
            { label: 'Z3A.31' },
            { label: 'Z3A.32' },
            { label: 'Z3A.33' },
            { label: 'Z3A.34' },
            { label: 'Z3A.35' },
            { label: 'Z3A.36' },
            { label: 'Z3A.37' },
            { label: 'Z3A.38' },
            { label: 'Z3A.40' }
        ]
    },
    'ccs': {
        'cpt' : [ 
            { value: '', label: "Please Select ..."},
            { label: '87625' },
            { label: '88141' }
        ],
        'icd10' : [ 
            { value: '', label: "Please Select ..."},
            { label: 'Z01.411' },
            { label: 'Z01.419' }
        ],
        'cltv' : [ 
            { value: '', label: "Please Select ..."},
            { label: 'ABNORMAL' },
            { label: 'NEGATIVE' }
        ],
        'loinc' : [ 
            { value: '', label: "Please Select ..."},
            { label: '19764-0' },
            { label: '47527-7' },
            { label: '47528-5' },                       
            { label: '59264-2' },
            { label: '59263-4' }
        ],        
    },
    'gsd': {
        'cpt' : [ 
            { value: '', label: "Please Select ..."},
            { label: '83036' },
            { label: '83037' }
        ],
        'cptii' : [ 
            { value: '', label: "Please Select ..."},
            { label: '3044F' },
            { label: '3046F' },
            { label: '3051F' },
            { label: '3052F' }
        ],        
        'icd10' : [ 
            { value: '', label: "Please Select ..."},
            { label: 'E10.8' },
            { label: 'E10.9' },
            { label: 'E10.65' },
            { label: 'E10.69' },
            { label: 'E11.8' },
            { label: 'E11.9' },
            { label: 'E11.65' },
            { label: 'E11.69' }
        ],
    },    
    'eed': {
        'cptii' : [ 
            { value: '', label: "Please Select ..."},
            { label: '2022F' },
            { label: '2023F' }
        ]       
    },    
    'bpd': {
        'cpt' : [ 
            { value: '', label: "Please Select ..."},
            { label: '83036' }
        ],
        'cptii' : [ 
            { value: '', label: "Please Select ..."},
            { label: '3044F' },
            { label: '3046F' },
            { label: '3051F' },
            { label: '3052F' }
        ],
        'cptiieyeexam': [
            { value: '', label: "Please Select ..."},
            { label: '2022F' },                                                
            { label: '2023F' }
        ],   
        'cptiisystolic': [
            { value: '', label: "Please Select ..."},
            { label: '3074F' },                                                
            { label: '3075F' }
        ],   
        'cptiidiastolic': [
            { value: '', label: "Please Select ..."},
            { label: '3078F' },                                                
            { label: '3079F' },
            { label: '3080F' }
        ],          
        'icd10' : [ 
            { value: '', label: "Please Select ..."},
            { label: 'E10.8' },
            { label: 'E10.9' },
            { label: 'E10.65' },
            { label: 'E10.69' },
            { label: 'E11.8' },
            { label: 'E11.9' },
            { label: 'E11.65' },
            { label: 'E11.69' }
        ],
        'loinc' : [ 
            { value: '', label: "Please Select ..."},
            { label: '19764-0' },
            { label: '47527-7' },
            { label: '47528-5' },                       
            { label: '59264-2' },
            { label: '59263-4' }
        ],        
    }
}