import './App.css';

import { Routes, Route } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import ButtonAppBar from './components/NavBar';
import { Home } from "./pages/Home";

import { HedisTable } from "./components/EntryForm";

import { columns_cis } from "./Forms"
import { columns_cbp } from "./Forms"
import { columns_ppc } from "./Forms"
import { columns_gsd } from "./Forms"
import { columns_eed } from "./Forms"
import { columns_bpd } from "./Forms"
import { columns_ccs } from "./Forms"
import { columns_lsc } from "./Forms"
import { columns_apm } from "./Forms"
import { columns_wcv } from "./Forms"
import { columns_wcc } from "./Forms"

import { columns_dhcf_immunizations } from "./Forms"
import { columns_mmisbump } from "./Forms"
import { columns_immunization_supplemental } from "./Forms"
import { columns_manual_supplemental } from "./Forms"

import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import { useState, useEffect } from "react"

import useIdle from "./components/useIdleTimer.js";

function App({ msalInstance }) {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  const [showModal, setShowModal] = useState(false);
  const [remainingTime, setRemainingTime] = useState(0);

  const handleIdle = () => {
    setShowModal(true); //show modal
    setRemainingTime(30);
    console.log("HOME-Idling")
  }

  const { isIdle } = useIdle({ onIdle: handleIdle, idleTime: process.env.REACT_APP_TIMEOUT }); 

  //app timer
  useEffect(() => {
      let interval;
  
      if (isIdle && showModal) {
        interval = setInterval(() => {
          setRemainingTime(
            (prevRemainingTime) =>
              prevRemainingTime > 0 ? prevRemainingTime - 1 : 0 //reduces the second by 1
          );
        }, 1000);
      }
  
      return () => {
        clearInterval(interval);
      };
    }, [isIdle, showModal]);

    //did app timer timeout
    useEffect(() => {
      if (remainingTime === 0 && showModal) {

        setShowModal(false);

        localStorage.clear();
        const account = instance.getActiveAccount();
        instance.logoutRedirect({ account: account, postLogoutRedirectUri: "/" });
        //instance.logoutRedirect(logoutRequest)
      }
    }, [instance, remainingTime, showModal]); // this is responsoble for logging user out after timer is down to zero and they have not clicked anything
  
    const handleLogOut = () => {
      setShowModal(false);
      const logoutRequest = {
        postLogoutRedirectUri: "/",
      };
      instance.logoutRedirect(logoutRequest)
    };
  
    const handleStayLoggedIn = () => {
      setShowModal(false);
  
    };

    function millisToMinutesAndSeconds(millis) {
      var minutes = Math.floor(millis / 60000);
      var seconds = ((millis % 60000) / 1000).toFixed(0);
      return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
    }

  return (
    <>
            {isIdle && isAuthenticated && showModal && (
            <div className="modal">
              <div className="modal-content">
                <h2>Idle Timeout Warning</h2>
                <p>You are about to be logged out due to inactivity.</p>
                <br />
                Time remaining: {millisToMinutesAndSeconds(remainingTime * 1000)}
                <br />
                <div className="row">
                <button className="btn btn-danger" onClick={handleLogOut}>
                  Logout
                </button>
                <button className="btn btn-primary " style={{float:"right"}} onClick={handleStayLoggedIn}>
                  Stay Logged In
                </button>
                </div>
    
              </div>
            </div>
          )}
    <div className="App">
      {/*<MsalProvider instance={msalInstance}>*/}
      <MsalProvider instance={msalInstance}>
        <ButtonAppBar></ButtonAppBar>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="CIS-IMA" element={isAuthenticated ? <HedisTable columns={columns_cis} dbtable={"cis"} title={"CIS-IMA"}/> : ""} />
            <Route path="CBP" element={isAuthenticated ? <HedisTable columns={columns_cbp} dbtable={"cbp"} title={"CBP"}/> : ""} />
            <Route path="PPC" element={isAuthenticated ? <HedisTable columns={columns_ppc} dbtable={"ppc"} title={"PPC"}/> : ""} />
            <Route path="GSD" element={isAuthenticated ? <HedisTable columns={columns_gsd} dbtable={"gsd"} title={"GSD"}/> : ""} />
            { isAuthenticated ? <>
            <Route path="EED" element={<HedisTable columns={columns_eed} dbtable={"eed"} title={"EED"}/>} />
            <Route path="BPD" element={<HedisTable columns={columns_bpd} dbtable={"bpd"} title={"BPD"}/>} />

            <Route path="CCS" element={<HedisTable columns={columns_ccs} dbtable={"ccs"} title={"CCS"}/>} />
            <Route path="LSC" element={<HedisTable columns={columns_lsc} dbtable={"lsc"} title={"LSC"}/>} />
            <Route path="APM" element={<HedisTable columns={columns_apm} dbtable={"apm"} title={"APM"}/>} />
            <Route path="WCV" element={<HedisTable columns={columns_wcv} dbtable={"wcv"} title={"WCV"}/>} />
            <Route path="WCC" element={<HedisTable columns={columns_wcc} dbtable={"wcc"} title={"WCC"}/>} />

            <Route path="DHCFImmunization" element={<HedisTable columns={columns_dhcf_immunizations} dbtable={"dhcf_immunizations"} title={"DHCF Immunizations"}/>} />
            <Route path="DHCFMMSBump" element={<HedisTable columns={columns_mmisbump} dbtable={"mmis_bump"} title={"MMIS Bump"}/>} />
            <Route path="ImmunizationSupplemental" element={<HedisTable columns={columns_immunization_supplemental} dbtable={"immunizationsupplemental"} title={"Immunization Supplemental"}/>} />
            <Route path="ManualSupplemental" element={<HedisTable columns={columns_manual_supplemental} dbtable={"manualsupplemental"} title={"Manual Supplemental"}/>} />
            </> : "" }
        </Routes>
      </MsalProvider>
      <AuthenticatedTemplate></AuthenticatedTemplate>
      <UnauthenticatedTemplate></UnauthenticatedTemplate>
    </div>
    </>
  );
}
/*
            <Route path="CIS-IMA" element={<HedisTable columns={columns_cis} dbtable={"cis"} title={"CIS-IMA"}/>} />
            <Route path="CBP" element={<HedisTable columns={columns_cbp} dbtable={"cbp"} title={"CBP"}/>} />
            <Route path="PPC" element={<HedisTable columns={columns_ppc} dbtable={"ppc"} title={"PPC"}/>} />
            <Route path="GSD" element={<HedisTable columns={columns_gsd} dbtable={"hbp"} title={"GSD"}/>} />
*/
export default App;
